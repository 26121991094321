<template>
    <modal ref="modalReclamo" titulo="Iniciar Reclamo" icon="close" :cargado-modal="cargando" @guardar="iniciar_reclamo">
        <ValidationObserver ref="form2">
            <div class="row mx-0 justify-center">
                <div class="col-10 text-center text-general">
                    Por favor escoja un motivo por el cual quiere inicar el reclamo.
                </div>
            </div>
            <div class="row mx-0 my-3 justify-center">
                <div class="col-10">
                    <label class="text-general f-14 pl-3">Motivo de reclamo</label>
                    <ValidationProvider v-slot="{ errors }" rules="required" name="motivo">
                        <el-select v-model="model.id_motivo" size="small" class="w-100">
                            <el-option
                            v-for="item in motivos"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                        <span class="text-danger f-11">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <ValidationProvider v-slot="{ errors }" rules="max:1000" name="justificación" tag="div" class="col-10 mt-3">
                    <label class="text-general f-14 pl-3">Cuéntanos la situación</label>
                    <el-input v-model="model.justificacion" type="textarea" :rows="5" />
                    <span class="text-danger f-11">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
            <div class="row mx-0 justify-center mt-4">
                <div class="col-10">
                    <div class="row mx-0 justify-center">
                        <div class="col px-0 mr-2">
                            <slim-cropper ref="fotoReclamo1" :options="slimOptions" class="border cr-pointer" style="height:180px;width:100%;background:#F8F9FF;border-radius:12px;" />
                        </div>
                        <div class="col px-0 ml-2">
                            <slim-cropper ref="fotoReclamo2" :options="slimOptions" class="border cr-pointer" style="height:180px;width:100%;background:#F8F9FF;border-radius:12px;" />
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Pedidos from '~/services/pedidos/pedidos-admin'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            model:{
                id_motivo:null,
                justificacion:'',
            },
            motivos:[],
            cuentanos: '',
            disabled: '',
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            cargando: false
        }
    },
    computed:{
        ...mapGetters({
            pedido: 'pedidos/pedidos_historial_tendero/pedido',
        }),
        id_pedido(){
            return this.pedido.id
        }
    },
    methods:{
        toggle(){
            //console.log(this.pedido);
            this.select_motivos()
            this.model = {
                id_motivo:null,
                justificacion:''
            }
            this.$refs.modalReclamo.toggle()
        },
        async select_motivos(){
            try {
                let params = {
                    tipo:5
                }
                const {data} = await Pedidos.select_motivos(params)
                this.motivos = data.motivos

            } catch (e){
                this.error_catch(e)
            }
        },
        async iniciar_reclamo(){
            try {
                const valid = await this.$refs.form2.validate()
                if(valid){
                    if(this.id_pedido === null){
                        this.notificacion('Alerta','Por favor seleccione un pedido','warning')
                        return
                    }
                    const fotoReclamo1 = this.$refs.fotoReclamo1.instanciaCrop.dataBase64.output.image
                    const fotoReclamo2 = this.$refs.fotoReclamo2.instanciaCrop.dataBase64.output.image
                    let imagenes = []
                    if(fotoReclamo1){
                        imagenes.push(fotoReclamo1)
                    }
                    if(fotoReclamo2){
                        imagenes.push(fotoReclamo2)
                    }

                    this.model.imagenes = imagenes
                    this.cargando = true

                    const {data} = await Pedidos.iniciar_reclamo(this.id_pedido,this.model)
                    this.$emit('update')
                    this.$store.dispatch('pedidos/pedidos_historial_tendero/pedidos_productos',this.id_pedido)
                    // this.$store.dispatch('pedidos/pedidos_historial_tendero/pedidos_historial',this.id_pedido)
                    this.notificacion('Mensaje','Reclamo iniciado','success')
                    this.limpiar()
                    this.$refs.modalReclamo.toggle()
                }
            } catch (e){
                this.error_catch(e)
            } finally{
                this.cargando = false
            }
        },
        limpiar(){
            this.model = {
                id_motivo:null,
                justificacion:''
            }
            this.$refs.fotoReclamo1.instanciaCrop.remove()
            this.$refs.fotoReclamo2.instanciaCrop.remove()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
